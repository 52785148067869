import { graphql } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import parse from "html-react-parser";
import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Layout from "../components/layout";

const PolicyTemplate = ({ data }) => {
  const { wpPolicie } = data;
  const { siteUrl } = data.site.siteMetadata;

  return (
    <Layout defaultHeader={true}>
      <GatsbySeo
        title={wpPolicie.title}
        description={wpPolicie.title}
        language="en"
        openGraph={{
          type: "website",
          url: `${siteUrl}${wpPolicie.uri}`,
          title: `${wpPolicie.title}`,
          description: `${wpPolicie.title}`,
        }}
      />
      <section className="bg-primary py-5">
        <Container className="my-6">
          <Row>
            <Col>
              <h1 className="display-4 text-center pb-5">{wpPolicie.title}</h1>
            </Col>
          </Row>
          {wpPolicie.content && (
            <Row>
              <Col>
                <div className="policy blog-content px-3 px-lg-0">
                  {parse(wpPolicie.content)}
                </div>
              </Col>
            </Row>
          )}
        </Container>
      </section>
    </Layout>
  );
};

export default PolicyTemplate;

export const blogData = graphql`
  query ($id: String!) {
    wpPolicie(id: { eq: $id }) {
      title
      content
      uri
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
